export const MY_RENDERER_STATUS = {
  unsubscribed: {
    id: 0,
  },
  subscribed: {
    id: 1,
  },
  suspend: {
    id: 2,
  },
}

export enum BilateralEnum {
  UNPAID = 0,
  PAID = 1,
}

export enum CanPartialRenderEnum {
  UNPAID = 0,
  PAID = 1,
}

export enum IsGiftDeliveredEnum {
  UNDELIVERED = 0,
  DELIVERED = 1,
}
